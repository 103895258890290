<div class="template">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <h2 class="mobileCenter mobileCenter-left mt-1">Clienți</h2>
            </div>
            <div class="col-xs-12 col-md-6">
                <button class="btn-default-dark mobileCenter mobileCenter-right" data-toggle="modal" data-target="#addModal">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i> Adaugă client
        </button>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control mt-3 mb-4" [(ngModel)]="term" style="border:1px solid #ced4da;" placeholder="Căutare client">
            </div>
            <div class="col-12">
                <div class="table-responsive" *ngIf="clients">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">ID</th>
                                <th scope="col">Nume</th>
                                <th scope="col">Telefon</th>
                                <th scope="col">Înălțime</th>
                                <th scope="col">Vârstă</th>
                                <th scope="col">Genul</th>
                                <th scope="col">Actiune</th>
                                <th scope="col">Optiuni</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of clients | filter:term | paginate: { itemsPerPage: itemPerPage, currentPage: curentPage }">
                                <td class="align-middle text-center">{{ item.id }}</td>
                                <td class="align-middle text-center">{{ item.name }}</td>
                                <td class="align-middle text-center">{{ item.phone }}</td>
                                <td class="align-middle text-center">{{ item.height }}</td>
                                <td class="align-middle text-center">{{ item.age }} </td>
                                <td class="align-middle text-center">{{ item.gen }}</td>

                                <td class="align-middle text-center">
                                    <button type="button" class="btn-default-dark" data-toggle="modal" data-target="#descriptionModal" (click)="viewProfile(item.id)">Vezi profilul</button>
                                </td>
                                <td class="align-middle text-center options">
                                    <i class="fa fa-edit" (click)="selectForEdit(item)" data-toggle="modal" data-target="#editModal"></i>
                                    <i class="fa fa-trash" (click)="deleteClient(item.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls autoHide="true" class="text-center" (pageChange)="curentPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>


<!-- client to edit modal -->

<!-- Modal -->
<div id="editModal" #editModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifică client</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="editForm" (ngSubmit)="submitEdit()" class="login100-form validate-form">

                    <!-- input nume client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="name" class="input100 form-control" type="text" placeholder="Numele clientului" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="name.invalid && (name.dirty || name.touched )">
                        <div *ngIf="name.errors['required']">Este necesar numele clientului</div>
                    </div>

                    <!-- input telefon client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="phone" class="input100 form-control" type="text" placeholder="Telefonul clientului" [ngClass]="{ 'is-invalid': phone.invalid && (phone.dirty || phone.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="phone.invalid && (phone.dirty || phone.touched )">
                        <div *ngIf="phone.errors['required']">Este necesar telefonul clientului</div>
                    </div>

                    <!-- input înăltimea client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="height" class="input100 form-control" type="text" placeholder="Înăltimea clientului" [ngClass]="{ 'is-invalid': height.invalid && (height.dirty || height.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="height.invalid && (height.dirty || height.touched )">
                        <div *ngIf="height.errors['required']">Este necesară înălțimea clientului</div>
                    </div>

                    <!-- input vârsta client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="age" class="input100 form-control" type="text" placeholder="Vârsta clientului" [ngClass]="{ 'is-invalid': age.invalid && (age.dirty || age.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-sort-numeric-asc" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="age.invalid && (age.dirty || age.touched )">
                        <div *ngIf="age.errors['required']">Este necesar vârsta clientului</div>
                    </div>

                    <!-- input genul client -->
                    <div class="wrap-input100 validate-input mb-4">
                        <input formControlName="gen" class="input100 form-control" type="text" placeholder="Genul clientului" [ngClass]="{ 'is-invalid': gen.invalid && (gen.dirty || gen.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-mars" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="gen.invalid && (gen.dirty || gen.touched )">
                        <div *ngIf="gen.errors['required']">Este necesar genul clientului</div>
                    </div>

                    <button [disabled]="editForm.invalid" type="submit" class="btn-default-dark d-block text-center m-auto">
            Trimite
          </button>

                </form>


            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- client to add modal -->

<!-- Modal -->
<div id="addModal" #addModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Adaugă client</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="addForm" (ngSubmit)="submitAdd()" class="login100-form validate-form">

                    <!-- input nume client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="name" class="input100 form-control" type="text" placeholder="Numele clientului" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="name.invalid && (name.dirty || name.touched )">
                        <div *ngIf="name.errors['required']">Este necesar numele clientului</div>
                    </div>

                    <!-- input telefon client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="phone" class="input100 form-control" type="text" placeholder="Telefonul clientului" [ngClass]="{ 'is-invalid': phone.invalid && (phone.dirty || phone.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="phone.invalid && (phone.dirty || phone.touched )">
                        <div *ngIf="phone.errors['required']">Este necesar telefonul clientului</div>
                    </div>

                    <!-- input înăltimea client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="height" class="input100 form-control" type="text" placeholder="Înăltimea clientului" [ngClass]="{ 'is-invalid': height.invalid && (height.dirty || height.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="height.invalid && (height.dirty || height.touched )">
                        <div *ngIf="height.errors['required']">Este necesară înălțimea clientului</div>
                    </div>

                    <!-- input vârsta client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="age" class="input100 form-control" type="text" placeholder="Vârsta clientului" [ngClass]="{ 'is-invalid': age.invalid && (age.dirty || age.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-sort-numeric-asc" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="age.invalid && (age.dirty || age.touched )">
                        <div *ngIf="age.errors['required']">Este necesar vârsta clientului</div>
                    </div>

                    <!-- input genul client -->
                    <div class="wrap-input100 validate-input mb-4">
                        <input formControlName="gen" class="input100 form-control" type="text" placeholder="Genul clientului" [ngClass]="{ 'is-invalid': gen.invalid && (gen.dirty || gen.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-mars" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="gen.invalid && (gen.dirty || gen.touched )">
                        <div *ngIf="gen.errors['required']">Este necesar genul clientului</div>
                    </div>

                    <button [disabled]="addForm.invalid" type="submit" class="btn-default-dark d-block text-center m-auto">
            Trimite
          </button>

                </form>


            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>
</div>