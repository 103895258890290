import { Injectable } from '@angular/core';
import { Client } from '../_models/Client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  getClients() {
    return this.http.get<Client[]>(environment.URL + 'client');
  }

  getClient(id: number) {
    return this.http.get<Client>(environment.URL + 'client/' + id);
  }

  createClient(client: Client) {
    return this.http.post(environment.URL + 'client', client);
  }

  deleteClient(clientID: number) {
    return this.http.delete(environment.URL + 'client/' + clientID);
  }

  editClient(client: Client) {
    return this.http.put(environment.URL + 'client/' + client.id, client);
  }

  getClientEvolution(clientID: number) {
    return this.http.get(environment.URL + 'clientEvolution/' + clientID);
  }
  
  getClientMeasurements(clientID: number){
    return this.http.get(environment.URL + 'clientMeasurements/' + clientID);
  }
}
