<div class="template">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-11 col-sm-11 col-md-10 col-lg-10">
                <h2>Programări</h2>
            </div>
        </div>
        <hr>

        <div class="row text-center">
            <div class="col-12 col-md-6 col-lg-5">
                <div class="btn-group btnGroupsMobile">
                    <div class="btn-default-dark mr-2" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        <i class="fa fa-arrow-left pr-2"></i> Previous
                    </div>
                    <div class="btn-default-dark mr-2" mwlCalendarToday [(viewDate)]="viewDate">
                        Today
                    </div>
                    <div class="btn-default-dark" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        <i class="fa fa-arrow-right pr-2"></i> Next
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2 verticalAlign text-center">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="col-12 col-md-6 col-lg-5">
                <div class="btn-group btnGroupsMobile">
                    <div class="btn-default-dark mr-2" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                        Month
                    </div>
                    <div class="btn-default-dark mr-2" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                        Week
                    </div>
                    <div class="btn-default-dark" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                        Day
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
        </div>

        <div class="row mb-3 mt-5">
            <div class="col-xs-12 col-md-6">
                <h3 class="mobileCenter mobileCenter-left mt-1"> Listă programări viitoare + ultimele 7 zile </h3>
            </div>
            <div class="col-xs-12 col-md-6">
                <button class="btn-default-dark mobileCenter mobileCenter-right" (click)="createSchedule()">
          <i class="fa fa-plus-circle"></i> Adauga programare
        </button>
            </div>
        </div>





        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Titlu</th>
                        <th>Detalii</th>
                        <th>Pret</th>
                        <th>Începere</th>
                        <th>Terminare</th>
                        <th>Operatiuni</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let event of events">
                        <td>
                            <input type="text" class="form-control input-min" [(ngModel)]="event.title" (focusout)="refresh.next()" />
                        </td>
                        <td>
                            <input type="text" class="form-control input-min" [(ngModel)]="event.details" (focusout)="refresh.next()" />
                        </td>
                        <td>
                            <input type="number" class="form-control input-min" [(ngModel)]="event.price" (focusout)="refresh.next()" />
                        </td>
                        <td>
                            <input class="form-control input-min" type="text" mwlFlatpickr [enable]="[{ from: '2020-04-01', to: '3030-05-01' }]" [(ngModel)]="event.start" (ngModelChange)="refresh.next()" (focusout)="editSchedule(event)" [altInput]="true" [convertModelValue]="true"
                                [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
                        </td>
                        <td>
                            <input class="form-control input-min" type="text" mwlFlatpickr [enable]="[{ from: '2020-04-01', to: '3030-05-01' }]" [(ngModel)]="event.end" (ngModelChange)="refresh.next()" (focusout)="editSchedule(event)" [altInput]="true" [convertModelValue]="true"
                                [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
                        </td>
                        <td>
                            <div class="btn-group d-block text-center">
                                <button class="btn btn-danger" (click)="deleteSchedule(event)">
                  <i class="fa fa-trash mr-2"></i> Ștergere
                </button>
                                <button class="btn btn-warning" (click)="editSchedule(event)">
                  <i class="fa fa-edit"></i> Salvează
                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ng-template #modalContent let-close="close">
            <div class="modal-header">
                <h5 class="modal-title">Programarea pe numele: {{ modalData?.event.title }}</h5>
                <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div>
                    <div>
                        <p class="d-inline font-weight-bold"> <i class="fa fa-hourglass-start mr-2"></i> Începere: </p>
                        <p class="d-inline ml-2 font-weight-bold">{{ modalData?.event.start | date: "HH:mm:ss" }} - </p>
                        <p class="d-inline ml-2">{{ modalData?.event.start }}</p>
                    </div>

                    <div>
                        <p class="d-inline font-weight-bold"> <i class="fa fa-flag-checkered mr-2"></i>Terminare: </p>
                        <p class="d-inline ml-2 font-weight-bold">{{ modalData?.event.end | date: "HH:mm:ss" }} - </p>
                        <p class="d-inline ml-2">{{ modalData?.event.end }}</p>
                    </div>

                    <div>
                        <p class="d-inline font-weight-bold"> <i class="fa fa-flag-checkered mr-2"></i>Preț: </p>
                        <p class="d-inline ml-2">{{ modalData?.event.price}} </p>
                    </div>

                    <div>
                        <p class="d-inline font-weight-bold"><i class="fa fa-info-circle mr-2"></i> Detalii: </p>
                        <p class="d-inline"> {{ modalData?.event.details }}</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" (click)="close()">
          OK
        </button>
            </div>
        </ng-template>

        <!-- all schedules -->

        <div class="row mt-5">
            <div class="col-12">
                <h3>Toate programările</h3>
            </div>
            <div class="col-12">
                <input type="text" class="form-control mt-3 mb-4" [(ngModel)]="filterTerm" style="border:1px solid #ced4da;" placeholder="Căutare">
            </div>
            <p>schedulesAll:{{schedulesAll}}</p>
            <div class="col-12">
                <div class="table-responsive" *ngIf="schedulesAll">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">ID</th>
                                <th scope="col">Titlu</th>
                                <th scope="col">Detalii</th>
                                <th scope="col">Preț</th>
                                <th scope="col">Începere</th>
                                <th scope="col">Terminare</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let iter of schedulesAll | filter:filterTerm | paginate: { itemsPerPage: itemPerPage, currentPage: curentPage }">
                                <td class="align-middle text-center">{{ iter.id }}</td>
                                <td class="align-middle text-center">{{ iter.title }}</td>
                                <td class="align-middle text-center">{{ iter.details }}</td>
                                <td class="align-middle text-center">{{ iter.price }}</td>
                                <td class="align-middle text-center">{{ iter.start }} </td>
                                <td class="align-middle text-center">{{ iter.end }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls autoHide="true" class="text-center" (pageChange)="curentPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>