import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import { addDays, isSameDay, isSameMonth, parseISO } from 'date-fns';
import { empty, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { ScheduleService } from 'src/app/_services/schedule.service';
import { Schedule } from 'src/app/_models/Schedule';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})
export class ScheduleComponent implements OnInit {
  AppComponent = new AppComponent(this.router);

  filterTerm = '';
  itemPerPage = 14;
  curentPage = 1;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  events: CalendarEvent[];
  schedulesAll: CalendarEvent[];

  constructor(
    private modal: NgbModal,
    private scheduleService: ScheduleService,
    public toastr: ToastrManager,
    private router: Router
  ) {
    this.AppComponent.checkAuth();
  }

  ngOnInit() {
    this.getAllSchedules();
    this.getSchedulesCalendar();
  }

  getSchedulesCalendar() {
    this.scheduleService.getSchedules().subscribe(
      (data) => {
        this.events = data;
        this.events
          .sort(
            (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
          )
          .forEach((element) => {
            element.start = parseISO(String(element.start));
            element.end = parseISO(String(element.end));
          });
        this.refresh.next();
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Programarile nu au putut fi preluate! '
        );
      }
    );
  }

  getAllSchedules() {
    this.scheduleService.getSchedulesAll().subscribe(
      (response) => {
        this.schedulesAll = response;
        this.refresh.next();
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Programarile nu au putut fi preluate! '
        );
      }
    );
  }

  createSchedule() {
    var newSchedule = new Schedule();
    newSchedule.title = 'Programare noua';
    newSchedule.start = addDays(new Date(), 0);
    newSchedule.end = addDays(new Date(), 0);

    this.scheduleService.createSchedule(newSchedule).subscribe(
      () => {
        this.getAllSchedules();
        this.getSchedulesCalendar();
        this.toastr.successToastr('Programarea a fost adaugata in lista! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Programarea NU a putut fi adaugata! '
        );
      }
    );
  }

  deleteSchedule(eventToDelete: CalendarEvent) {
    var eventID: number = eventToDelete.id;
    this.scheduleService.deleteSchedule(eventID).subscribe(
      () => {
        this.getAllSchedules();
        this.getSchedulesCalendar();
        this.toastr.successToastr('Programarea a fost stearsa in lista! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Programarea NU a putut fi stearsa! '
        );
      }
    );
  }

  editSchedule(schedule: Schedule) {
    var validationResult = this.scheduleValidation(schedule);

    if (validationResult.success) {
      this.scheduleService.editSchedule(schedule).subscribe(
        () => {
          this.getAllSchedules();
          this.getSchedulesCalendar();
          this.toastr.successToastr('Programarea a fost editata! ');
        },
        (error) => {
          this.toastr.errorToastr(
            error.message,
            'Programarea NU a putut fi editata! '
          );
        }
      );
    } else {
      if (validationResult.errorsCount >= 1) {
        this.toastr.errorToastr(
          'Campurile urmatoare nu sunt validate: ' + validationResult.errors,
          'Eroare! '
        );
      }
    }
  }

  scheduleValidation(schedule: Schedule) {
    var regExpText = new RegExp(/^[a-zA-Z\s.]*$/);
    var errors = '';
    var errorsCount: number = 0;
    if (!regExpText.test(schedule.title)) {
      errors += 'titlu';
      errorsCount++;
    }

    if (!regExpText.test(schedule.details)) {
      errors += ' , detalii';
      errorsCount++;
    }

    var success = errorsCount >= 1 ? false : true;

    return {
      success: success,
      errorsCount: errorsCount,
      errors: errors,
    };
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
