 <!--====== PRELOADER PART START ======-->

 <div class="preloader">
   <div class="loader_34">
     <div class="ytp-spinner">
       <div class="ytp-spinner-container">
         <div class="ytp-spinner-rotator">
           <div class="ytp-spinner-left">
             <div class="ytp-spinner-circle"></div>
           </div>
           <div class="ytp-spinner-right">
             <div class="ytp-spinner-circle"></div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>

 <!--====== PRELOADER ENDS START ======-->

 <!--====== HEADER PART START ======-->

 <header id="home" class="header-area pt-100">

   <div class="navigation-bar">
     <div class="container">
       <div class="row">
         <div class="col-lg-12">
           <nav class="navbar navbar-expand-lg">
             <a class="navbar-brand" href="#">
               <img src="assets/images/index/logo.png" alt="Logo header">
             </a>
             <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
               aria-expanded="false" aria-label="Toggle navigation">
               <span class="toggler-icon"></span>
               <span class="toggler-icon"></span>
               <span class="toggler-icon"></span>
             </button>

             <div class="collapse navbar-collapse" id="navbarSupportedContent">
               <ul id="nav" class="navbar-nav ml-auto">
                 <li class="nav-item active">
                   <a class="page-scroll" href="#home">Acasă</a>
                 </li>
                 <li class="nav-item">
                   <a class="page-scroll" href="#about">Despre noi</a>
                 </li>
                 <li class="nav-item">
                   <a class="page-scroll" href="#service">Servicii</a>
                 </li>
                 <li class="nav-item">
                   <a class="page-scroll" href="#project">Nutriție</a>
                 </li>
                 <li class="nav-item">
                   <a class="page-scroll" href="#team">Echipă</a>
                 </li>
                 <!-- <li class="nav-item">
                   <a class="page-scroll" href="#testimonial">Testimoniale</a>
                 </li> -->
                 <!-- <li class="nav-item">
                   <a class="page-scroll" href="#contact">Contact</a>
                 </li> -->
               </ul> <!-- navbar nav -->
             </div>
             <div class="navbar-btn ml-20 d-none d-sm-block">
               <a class="main-btn" href="tel:+40744836920"><i class="lni-phone"></i> 0744 836 920</a>
             </div>
           </nav> <!-- navbar -->
         </div>
       </div> <!-- row -->
     </div> <!-- container -->
   </div> <!-- navigation bar -->

   <div class="header-banner d-flex align-items-center">
     <div class="container">
       <div class="row">
         <div class="col-xl-7 col-lg-6 col-sm-5 col-sm-3">
           <div class="banner-content">
             <!-- <h4 class="sub-title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1s">Nutritionist – Dietetician LICENTIAT</h4> -->
             <!-- <h1 class="banner-title mt-10 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="2s"><span>Smart</span> Lifestyle</h1> -->
             <h1 class="banner-title mt-10 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="2s">
               <img class="" src="assets/images/index/logo.png" alt="Logo">
             </h1>
             <h5 class="banner-contact mt-25 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="2.3s">Alimentație și prevenție pentru o viață sănătoasă</h5>
           </div> <!-- banner content -->
         </div>
       </div> <!-- row -->
     </div> <!-- container -->
     <div class="banner-image bg_cover" style="background-image: url(assets/images/index/banner/banner-image-1.jpg)"></div>
   </div> <!-- header banner -->

 </header>

 <!--====== HEADER PART ENDS ======-->

 <!--====== ABOUT PART START ======-->

 <section id="about" class="about-area pt-125 pb-130">
   <div class="container">
     <div class="row">
       <div class="col-lg-6">
         <div class="about-image mt-50 clearfix">
           <div class="single-image float-left">
             <img src="assets/images/index/about/about-1.jpg" alt="Despre noi poza 1">
           </div>
           <div class="single-image image-tow float-right">
             <img src="assets/images/index/about/about-2.jpg" alt="Despre noi poza 2">
           </div>
         </div>
       </div>
       <div class="col-lg-6">
         <div class="about-content">
           <h4 class="about-welcome">Despre noi </h4>
           <h3 class="about-title mt-10">Plan de nutriție <span>PERSONALIZAT</span></h3>
           <p class="mt-25">Se poate să eviți sau să amâni instalarea unor anumite boli pe fond genetic care se amplifică și datorită obiceiurilor culinare?
             <br> DA 💡💡💡 SE POATE!</p>
           <p class="mt-25">O alimentație sănătoasă nu trebuie să fie prea complicată. Dacă te simți copleșit de toate sfaturile conflictuale legate de nutriție și dietă, nu
             ești
             singurul. Se pare că pentru fiecare expert care îți spune că o anumită mâncare este bună pentru tine, vei găsi o altă zicală exact opusă. Modelul dvs. alimentar
             este cel mai important.
             <br> <br>Piatra de temelie a unei diete sănătoase ar trebui să fie înlocuirea alimentelor procesate cu alimente reale ori de câte ori este posibil. Consumul de
             alimente cât mai aproape de felul în care a făcut-o natura poate face o diferență uriașă în felul în care gândești, arăți și te simți.</p>
         </div>
       </div>
       <div class="col-lg-12">
         <div class="about-content mt-45">
           <h4 class="about-welcome">LPG </h4>
           <h3 class="about-title mt-10">Tratament de slăbire și remodelare <span>CORPORALĂ</span></h3>
           <h5 class="mt-25">LPG – aplicatii în medicina estetică, sănătate , terapia durerii și slăbire</h5>
           <p class="mt-25">
             Endermologia LPG Cellu M6 Integral este tehnica ce remodelează silueta în mod miraculos,
             soluție 100% naturală, elimină grăsimea fibroasă localizată, tratează celulita și redă fermitatea pielii,
             acționând acolo unde sportul și dieta nu mai dau rezultate.
             <br> <br>Stimularea celulară redă densitatea și tonifică straturile de piele până în profunzime, ștergând totodată semnele de îmbătrânire.
             <br> <br>De peste 30 de ani, tehnologiile unice, patentate de LPG SYSTEMS și-au confirmat poziția de locul 1 ale fizioterapeuților
             și medicilor esteticieni datorită siguranței dovedite în obținerea rezultatelor.
             <br> <br> Tehnicile LPG reprezintă în prezent standardul de îngrijire pre și post liposuctie,
             optimizând astfel rezultatul final. De asemenea, prin programele medicale special create se obțin rezultate uimitoare
             în tratrarea anumitor patologii precum:
           </p>
           <ul>
             <li>
               <p>Reabilitare a arsurilor, fibroze, tesuturi cicatrizate inestetic și piele îmbatranită</p>
             </li>
             <li>
               <p>Insuficiență venoasă și retenție de apă</p>
             </li>
             <li>
               <p>Rigiditate dureroasă și spasme musculare</p>
             </li>
             <li>
               <p>Reabilitare a tendoanelor și ligamentelor</p>
             </li>
           </ul>
           <p class="mt-25"> Pacientele noastre au observat rezultate optime realizând trei tratamente pe săptamână, rezultate fiind vizibile după 6-8 ședințe. După obținerea
             rezultatului dorit, o ședință pe săptămână va menține permanent efectele tratamentului. Durata unei ședințe LPG este de 35 minute. Dacă există o singură zonă
             problemă, ne putem
             limita și la 20 de minute</p>
           <p class="mt-25"> În salonul nostru poți pierde în centimetri, îți poți întineri corpul și fața fără vreun fel de injecții, fără chirurgie, intr-un mod foarte
             plăcut cu LIPOMASSAGE & LIFTMASSAGE.</p>

           <h5 class="mt-25">LPG Endormologie – metoda eficientă de remodelare corporală, facială și de reducere a celulitei!</h5>
           <p class="mt-25">Cu ajutorul modulului de tratament facial LPG se activează în mod natural producerea de colagen și elastină eliminând vizibil ridurile, pielea
             devine mai tânără, se ridică obrajii căzuți, obtinând un lifting facial non-chirurgical.</p>
           <p class="mt-25">Endermologia cu Cellu M6 este un tratament modern, non-invaziv și extrem de eficient în combaterea celulitei indiferent de gradul acesteia.
             Datorita costumului special nu exista riscul de a se sparge vasele capilare și nici de a se produce vânătăi.</p>
           <p class="mt-25">Lipomasajul LPG este defapt un masaj mecanic complex, care asociaza mișcarea de rulare a pielii cu aspirația. Acțiunea rolelor motorizate care
             pliază și depliază succesiv pielea, se adauga mecanismului de aspirare a pielii, realizând un veritabil masaj al țesutului adipos, cunoscut sub numele de
             lipomasaj.</p>
           <p class="mt-25">Mecanismele de acțiune asupra celulitei sunt complexe:</p>
           <ul>
             <li>
               <p>Reducerea fibrozei, stimularea producerii de colagen și elastina</p>
             </li>
             <li>
               <p>Activarea microcirculatiei până de patru ori cu cresterea aportului de oxigen și de substanțe nutritive și eliminarea rezidurilor metabolice și detoxifiere
               </p>
             </li>
             <li>
               <p>Eliminarea retentiei de lichide, drenajul limfatic creste de până de trei ori!</p>
             </li>
             <li>
               <p>Lipoliza, adică eliminarea pungilor de grăsime prinse între septurile fibroase</p>
             </li>
           </ul>
           <h5 class="mt-25">CONTRAINDICAȚII?</h5>
           <p class="mt-25">Majoritatea persoanelor pot apela la această procedură deoarece există foarte puține contraindicații, cum ar fi cancerul, tratamentul anticoagulant
             și cu corticoizi, infecții si răni ale pielii, tromboflebita, boli hematologice, iar pe timpul sarcinii se poate doar de la coapse în jos.</p>
           <p class="mt-25 ps">*Tratamentele de remodelare corporala sunt complementare unei alimentații echilibrate și exercițiului fizic pentru a obține cele mai bune
             rezultate
             în lupta împotriva celulitei și a adipozitatilor localizate. Rezultatele sunt eficiente dar nu garantate și diferă de la persoană la persoană.</p>
         </div>
         <div class="row mt-25 text-center">
           <div class="col-lg-6">
             <iframe src="https://www.youtube.com/embed/LkY1VcNeMzk" frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
           </div>
           <div class="col-lg-6">
             <iframe src="https://www.youtube.com/embed/3aLn0lrokfE" frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
           </div>
         </div>
         <div class="row">
           <div class="col-lg-12 text-center">
             <button type="button" class="main-btn mt-25" data-toggle="modal" data-target="#exampleModal">
              Lista prețuri
            </button>
           </div>
         </div>
       </div>
     </div> <!-- row -->
   </div> <!-- container -->
 </section>

 <!-- modal prices -->

 <!-- Modal -->
 <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Listă prețuri</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body text-center">
        <div class="col-lg-12">
          <img src="assets/images/index/about/preturi-1-2023.png" alt="Lista Preturi 1">
        </div>
        <div class="col-lg-12">
          <img src="assets/images/index/about/preturi-2-2023.png" alt="Lista Preturi 2">
        </div>
        <div class="col-lg-12">
          <img src="assets/images/index/about/preturi-3-2023.png" alt="Lista Preturi 3">
        </div>
       </div>
       <div class="modal-footer">
         <button type="button" class="main-btn" data-dismiss="modal">Închide</button>
       </div>
     </div>
   </div>
 </div>

 <!-- end modal prices -->

 <!--====== ABOUT PART ENDS ======-->

 <!--====== SERVICES PART START ======-->

 <section id="service" class="services-area pt-125 pb-50 gray-bg">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-12">
         <div class="section-title text-center pb-20">
           <h5 class="sub-title mb-15">Serviciile noastre</h5>
           <h2 class="title">Apelează la ajutorul unui specialist pentru rezultate sigure!</h2>
         </div> <!-- section title -->
       </div>
     </div> <!-- row -->
     <div class="row justify-content-center">
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
           <div class="services-icon">
             <i class="fa fa-id-card-o"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Plan alimenar personalizat</h4>
             <p class="mt-20">Promovăm un stil de viață sănătos. Prevenția este întotdeauna cheia. Noi iți putem
               oferi idei de meniuri absolut delicioase ce te vor ajuta să te simți în formă și plin de energie mereu!
             </p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.8s">
           <div class="services-icon">
             <i class="fa fa-free-code-camp"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Scădere în greutate</h4>
             <p class="mt-20"> Concepem diete personalizate în funcție de preferințele alimentare, program și buget.
               Nu mai amana! Fă astăzi o schimbare pentru totdeauna! </p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.2s">
           <div class="services-icon">
             <i class="lni lni-pizza"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Crestere in greutate</h4>
             <p class="mt-20">Dispunem de o paleta întreagă cu rețete delicioase și rapide ce te pot ajuta să iei în greutate fără să iți dai seama.
               Împreună putem ajunge acolo unde ți-ai dorit mereu!</p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
           <div class="services-icon">
             <i class="lni lni-users"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Nutriție prenatală</h4>
             <p class="mt-20">Diete personalizate pentru cele 3 trimestre de sarcină, prevenirea diabetului de sarcină,
               prevenirea excesului de greutate în sarcina și pentru dezvoltarea fătului în condiții de sănătate.</p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
           <div class="services-icon">
             <i class="fa fa-heartbeat"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Nutriție postnatală</h4>
             <p class="mt-20">Planuri alimentare special realizate pentru susținerea lactației și a refacerii organismului matern
               după naștere și calculul necesarului energetic pentru asigurarea nutrienților mamei și copilului.</p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.8s">
           <div class="services-icon">
             <i class="fa fa-child"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Nutriție pediatrică</h4>
             <p class="mt-20">Un plan alimentar personalizat, deoarece copilul se află într-o perioadă de creştere şi acumulări,
               aşadar regimul nu trebuie să interfereze cu dezvoltarea lui.</p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.2s">
           <div class="services-icon">
             <i class="fa fa-bicycle"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Nutriție sportivă</h4>
             <p class="mt-20">Nutriţia joacă un rol deosebit de important în sport, pentru obţinerea performanţelor maxime.
               Noi te putem îndruma către un stil de viață echilibrat în functie de necesități.</p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.2s">
           <div class="services-icon">
             <i class="fa fa-stethoscope"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Diete personalizate</h4>
             <p class="mt-20"> Indiferent de afecțiune: diabet, cancer, afecțiuni cardiovasculare, afecțiuni tiroidiene, astm, alergii, acnee, dezechilibre alimentare,
               afecțiuni renale, etc. </p>
           </div>
         </div> <!-- single services -->
       </div>
       <div class="col-lg-4 col-md-6 col-sm-8">
         <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.2s">
           <div class="services-icon">
             <i class="fa fa-cutlery"></i>
           </div>
           <div class="services-content mt-15">
             <h4 class="services-title">Meniuri</h4>
             <p class="mt-20">Concepem meniuri pentru: grădinițe, școli, restaurante, firme catering și diferite instituții.</p>
           </div>
         </div> <!-- single services -->
       </div>
     </div> <!-- row -->
   </div> <!-- container -->
 </section>

 <!--====== SERVICES PART ENDS ======-->

 <!--====== PROJECT PART START ======-->

 <section id="project" class="project-area pt-125 pb-130">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-6">
         <div class="section-title text-center pb-50">
           <h5 class="sub-title mb-15">Nutriție</h5>
           <h2 class="title">Delicii</h2>
         </div> <!-- section title -->
       </div>
     </div> <!-- row -->
   </div>
   <div class="container-fluid">
     <div class="row project-active">
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-6.jpg" alt="Delicii 1">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-7.jpg" alt="Delicii 2">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-8.jpg" alt="Delicii 3">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-9.jpg" alt="Delicii 4">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-10.jpg" alt="Delicii 5">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-11.jpg" alt="Delicii 6">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-12.jpg" alt="Delicii 7">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-1.jpg" alt="Delicii 8">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-2.jpg" alt="Delicii 9">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-3.jpg" alt="Delicii 10">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-4.jpg" alt="Delicii 11">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-5.jpg" alt="Delicii 12">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/project/p-13.jpg" alt="Delicii 13">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>

 <!--====== PROJECT PART ENDS ======-->

 <!--====== TEAM PART START ======-->

 <section id="team" class="team-area pt-125 pb-130  gray-bg">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-12">
         <div class="section-title text-center pb-20">
           <h5 class="sub-title mb-15">Echipa</h5>
           <h2 class="title">Lucrează la transformarea și îmbunătățirea stilului de viață</h2>
         </div> <!-- section title -->
       </div>
     </div> <!-- row -->
     <div class="row">
       <div class="col-lg-12 col-md-12 col-sm-12">
         <div class="single-team text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
           <div class="team-image">
             <img src="assets/images/index/team/Oana.jpeg" alt="Chelba Oana-Iulia">
           </div>
           <div class="team-content">
             <h4 class="team-name"><a href="#">Chelba Oana-Iulia</a></h4>
             <span class="sub-title">Nutriționist-Dietetician Licențiat</span>
             <ul class="social mt-25">
               <li><a href="https://www.facebook.com/smart_lifestylelpg-112726150625758/" target="_blank"><i class="lni-facebook-filled"></i></a></li>
               <li><a href="https://www.instagram.com/smart_lifestyle.lpg/?igshid=16wlouprdm3wm" target="_blank"><i class="lni-instagram"></i></a></li>
             </ul>
           </div>
         </div> <!-- single team -->
       </div>
     </div> <!-- row -->
     <div class="row carousel-team mt-5 project-area">
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-1.jpg" alt="Echipa 1">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-2.jpg" alt="Echipa 2">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-4.jpg" alt="Echipa 3">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-6.jpg" alt="Echipa 5">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-7.jpg" alt="Echipa 6">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-9.jpg" alt="Echipa 7">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-10.jpg" alt="Echipa 8">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="single-project">
           <div class="project-image">
             <img src="assets/images/index/team/t-11.jpg" alt="Echipa 9">
           </div>
           <div class="project-content">
             <!-- <a class="project-title" href="#">Home Interior Design</a> -->
           </div>
         </div>
       </div>
     </div>
   </div> <!-- container -->
 </section>

 <!--====== TEAM PART ENDS ======-->

 <!--====== TESTIMONIAL PART START ======-->

 <!-- <section id="testimonial" class="testimonial-area pt-125 pb-130">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-12">
         <div class="section-title text-center pb-20">
           <h5 class="sub-title mb-15">Testimoniale</h5>
           <h2 class="title">Părerea clienților despre noi</h2>
         </div>
       </div>
     </div>
     <div class="testimonial-bg bg_cover pt-80 pb-80" style="background-image: url(assets/images/index/testimonial/testimonial-bg-4.jpg)">
       <div class="row">
         <div class="col-xl-4 offset-xl-7 col-lg-5 offset-lg-6 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
           <div class="testimonial-active">
             <div class="single-testimonial text-center">
               <div class="testimonial-image">
                 <img src="assets/images/index/testimonial/t-1.jpg" alt="Testimoniale 1">
                 <div class="quota">
                   <i class="lni-quotation"></i>
                 </div>
               </div>
               <div class="testimonial-content mt-20">
                 <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.</p>
                 <h5 class="testimonial-name mt-15">Fajar</h5>
                 <span class="sub-title">Random Customer</span>
               </div>
             </div>
             <div class="single-testimonial text-center">
               <div class="testimonial-image">
                 <img src="assets/images/index/testimonial/t-2.jpg" alt="Testimoniale 2">
                 <div class="quota">
                   <i class="lni-quotation"></i>
                 </div>
               </div>
               <div class="testimonial-content mt-20">
                 <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.</p>
                 <h5 class="testimonial-name mt-15">Alina</h5>
                 <span class="sub-title">Tesla Motors</span>
               </div>
             </div>
             <div class="single-testimonial text-center">
               <div class="testimonial-image">
                 <img src="assets/images/index/testimonial/t-3.jpg" alt="Testimoniale 3">
                 <div class="quota">
                   <i class="lni-quotation"></i>
                 </div>
               </div>
               <div class="testimonial-content mt-20">
                 <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.</p>
                 <h5 class="testimonial-name mt-15">Celina</h5>
                 <span class="sub-title">CEO, Alo</span>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section> -->

 <!--====== TESTIMONIAL PART ENDS ======-->

 <!--====== CONTACT PART START ======-->

 <!-- <section id="contact" class="contact-area pt-125 gray-bg">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-6">
         <div class="section-title text-center pb-20">
           <h5 class="sub-title mb-15">Contactează-ne</h5>
           <h2 class="title">Ține legătura</h2>
         </div>
       </div>
     </div>
     <div class="row justify-content-center">
       <div class="col-lg-8">
         <div class="contact-form">
           <form id="contact-form" action="mail.php" method="post" data-toggle="validator">
             <div class="row">
               <div class="col-md-6">
                 <div class="single-form form-group">
                   <input type="text" name="name" placeholder="Numele" data-error="Name is required." required="required">
                   <div class="help-block with-errors"></div>
                 </div>
               </div>
               <div class="col-md-6">
                 <div class="single-form form-group">
                   <input type="email" name="email" placeholder="Email" data-error="Valid email is required." required="required">
                   <div class="help-block with-errors"></div>
                 </div>
               </div>
               <div class="col-md-6">
                 <div class="single-form form-group">
                   <input type="text" name="subject" placeholder="Subiect" data-error="Subject is required." required="required">
                   <div class="help-block with-errors"></div>
                 </div>
               </div>
               <div class="col-md-6">
                 <div class="single-form form-group">
                   <input type="text" name="phone" placeholder="Telefon" data-error="Phone is required." required="required">
                   <div class="help-block with-errors"></div>
                 </div>
               </div>
               <div class="col-md-12">
                 <div class="single-form form-group">
                   <textarea placeholder="Your Mesaage" name="message" data-error="Please,leave us a message." required="required"></textarea>
                   <div class="help-block with-errors"></div>
                 </div>
               </div>
               <p class="form-message"></p>
               <div class="col-md-12">
                 <div class="single-form form-group text-center">
                   <button type="submit" class="main-btn">Trimite</button>
                 </div>
               </div>
             </div>
           </form>
         </div>
       </div>
     </div>
   </div>
 </section> -->

 <!--====== CONTACT PART ENDS ======-->

 <!--====== MAP PART START ======-->

 <section id="map" class="map-area mt-50">
   <div class="mapouter">
     <div class="gmap_canvas">
       <iframe id="gmap_canvas"
         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1343.9826794280582!2d26.264284612413373!3d47.64623999790506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4734fc3988f1d8cd%3A0x8392d5dc0404f41!2sStrada%20Mir%C4%83u%C8%9Bilor%203%2C%20Suceava!5e0!3m2!1sen!2sro!4v1603719745502!5m2!1sen!2sro"
         frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
     </div>
   </div>
   <div class="map-bg bg_cover d-none d-lg-block" style="background-image: url(assets/images/index/map/map-bg.jpg)"></div>
 </section>

 <!--====== MAP PART ENDS ======-->

 <!--====== FOOTER PART START ======-->

 <footer id="footer" class="footer-area">
   <div class="footer-widget pt-20 pb-20">
     <div class="container">
       <div class="row">
         <div class="col-lg-6 col-md-6 col-sm-12">
           <div class="footer-logo mt-50">
             <a href="#">
               <img src="assets/images/index/logo.png" alt="Logo footer">
             </a>
             <ul class="footer-info">
               <li>
                 <div class="single-info">
                   <div class="info-icon">
                     <i class="lni-phone-handset"></i>
                   </div>
                   <div class="info-content">
                     <p>+40 744 836 920</p>
                   </div>
                 </div> <!-- single info -->
               </li>
               <li>
                 <div class="single-info">
                   <div class="info-icon">
                     <i class="lni-envelope"></i>
                   </div>
                   <div class="info-content">
                     <p>blanaru_o@yahoo.com</p>
                   </div>
                 </div> <!-- single info -->
               </li>
               <li>
                 <div class="single-info">
                   <div class="info-icon">
                     <i class="lni-map"></i>
                   </div>
                   <div class="info-content">
                     <p>Str. Mirautilor nr. 3, Suceava <br> la parterul cladirii 'Sigur la Volan'</p>
                   </div>
                 </div> <!-- single info -->
               </li>
             </ul>
             <ul class="footer-social mt-20">
               <li><a href="https://www.facebook.com/smart_lifestylelpg-112726150625758/" target="_blank"><i class="lni-facebook-filled"></i></a></li>
               <li><a href="https://www.instagram.com/smart_lifestyle.lpg/?igshid=16wlouprdm3wm" target="_blank"><i class="lni-instagram"></i></a></li>
             </ul>
           </div> <!-- footer logo -->
         </div>
         <div class="col-lg-6 col-md-6 col-sm-12">
           <div class="footer-link mt-45">
             <div class="f-title">
               <h4 class="title">Program</h4>
             </div>
             <ul class="mt-15">
               <li>Luni-Vineri: 10:00-18:00</li>
               <li>Sâmbătă: 10:00-16:00</li>
               <li>Duminica: închis</li>
             </ul>
           </div>
         </div>
         <!-- <div class="col-lg-3 col-md-5 col-sm-8">
           <div class="footer-newsleter mt-45">
             <div class="f-title">
               <h4 class="title">Newsleter</h4>
             </div>
             <p class="mt-15">Lorem ipsum dolor sit amet, consec tetur adipiscing elit.</p>
             <form action="#">
               <div class="newsleter mt-20">
                 <input type="email" placeholder="info@contact.com">
                 <button><i class="lni-angle-double-right"></i></button>
               </div>
             </form>
           </div>
         </div> -->
       </div> <!-- row -->
     </div> <!-- container -->
   </div> <!-- footer widget -->
   <div class="copyright-area">
     <div class="container">
       <div class="row">
         <div class="col-lg-12">
           <div class="copyright text-center">
             <p>@ <a href="https://uideck.com" target="_blank" rel="nofollow">UIdeck</a> |
               @ <a href="http://adrianiuga.ro" target="_blank" rel="nofollow"> Iuga Adrian-Valentin </a></p>
           </div> <!-- copyright -->
         </div>
       </div> <!-- row -->
     </div> <!-- container -->
   </div> <!-- copyright-area -->
 </footer>

 <!--====== FOOTER PART ENDS ======-->

 <!--====== BACK TO TOP PART START ======-->

 <a href="#" class="back-to-top"><i class="lni-chevron-up"></i></a>

 <!--====== BACK TO TOP PART ENDS ======-->

 <!-- row -->
