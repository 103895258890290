<div class="template">
    <div class="container-fluid">
        <div class="row">
            <!-- header for client PRINT-->
            <div *ngIf="isPrint">
                <div class="col-xs-12 col-md-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="intro mt-5">
                                <h2 class="text-left font-weight-bold">Nutriționist/Dietetician licențiat: Chelba Oana Iulia</h2>
                                <h2 class="text-center mt-5 font-weight-bold"> Măsurători TANITA</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-xs-12 col-md-12 d-flex">
                            <div class="text-center">
                                <img id="print-logo" src="./assets/images/index/logo.png" alt="Print Logo" width="30%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- header for client PRINT-->

            <!-- header for client PROFILE-->
            <div class="col-xs-12 col-md-12" *ngIf="!isPrint">
                <h2 class="mobileCenter mobileCenter-left mt-1">Profil client: {{ client?.name }}</h2>
            </div>
            <div class="col-xs-12 col-md-12" *ngIf="!isPrint">
                <button type="button" class="btn-default-dark mobileCenter mobileCenter-right" (click)="printClient(client?.id)">
          <i class="fa fa-print mr-2" aria-hidden="true"></i> Printează profil
        </button>
                <button type="button" class="btn-default-dark mobileCenter mobileCenter-right" (click)="goToClient()">
          <i class="fa fa-arrow-left mr-2" aria-hidden="true"></i> Înapoi la clienți
        </button>
            </div>
            <!-- header for client PROFILE-->
        </div>

        <hr>

        <div class="row">
            <div class="col-xs-12 col-md-6 d-flex mt-2 mb-5">
                <div class="mx-auto justify-content-center align-self-center">
                    <h2>Informații client</h2>
                    <p>Numele: {{ client?.name }}</p>
                    <p>Înălțime: {{ client?.height }} m</p>
                    <p>Vârstă: {{ client?.age }} ani</p>
                    <p>Gen: {{ client?.gen }}</p>
                </div>
            </div>
            <div class="col-xs-12 col-md-6 d-flex mt-2 mb-5">
                <div class="mx-auto justify-content-center align-self-center">
                    <h2>Date ultima vizită</h2>
                    <p>Indice de masă corporala: {{ indiceMasaCorporala }}</p>
                    <p>Rata Metabolică Bazală: {{ RMB }} kcal</p>
                    <p>Greutatea ideală: {{ greutateaIdeala }} kg</p>
                </div>
            </div>
            <!-- <div class="col-xs-12 col-md-12 mt-2 mb-5">
        <h2>Predispoziție boli</h2>
        <p>Persoana are {{ diseasPrediction }}% șanse de a se îmbolnăvi</p>
        <div id="chart">
          <apx-chart
            [series]="chartDiseases.series"
            [chart]="chartDiseases.chart"
            [xaxis]="chartDiseases.xaxis"
            [stroke]="chartDiseases.stroke"
            [tooltip]="chartDiseases.tooltip"
            [dataLabels]="chartDiseases.dataLabels"></apx-chart>
        </div>
      </div> -->
            <div class="col-12 mb-5">
                <h2>Grafic evoluție pacient</h2>
                <div id="chart">
                    <apx-chart [series]="chartPatientEvolution.series" [chart]="chartPatientEvolution.chart" [xaxis]="chartPatientEvolution.xaxis" [stroke]="chartPatientEvolution.stroke" [tooltip]="chartPatientEvolution.tooltip" [dataLabels]="chartPatientEvolution.dataLabels"></apx-chart>
                </div>
            </div>
            <div class="col-12 mb-5">
                <button *ngIf="!isPrint" type="button" class="btn-default-dark mobileCenter mobileCenter-right mb-3" data-toggle="modal" data-target="#addModal">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i> Adaugă intrare
        </button>
                <div class="table-responsive" *ngIf="client?.clients_profile">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="text-center">
                                <th scope="col" rowspan="2" class="align-middle">Data</th>
                                <th scope="col">Greutate</th>
                                <th scope="col">Grasime Corporala</th>
                                <th scope="col">Apa Corporala</th>
                                <th scope="col">Masa Musculara</th>
                                <th scope="col">Rating Psihic</th>
                                <th scope="col">Masa Osoasa</th>
                                <th scope="col">BMR</th>
                                <th scope="col">Varsta Metabolica</th>
                                <th scope="col">Grasime Viscerala</th>
                                <th scope="col" rowspan="2" class="align-middle">Observații</th>
                                <th scope="col" *ngIf="!isPrint" rowspan="2" class="align-middle">Optiuni</th>
                            </tr>
                            <tr class="text-center">
                                <th scope="col">
                                    <img src="assets/images/admin/greutate.png" alt="Greutate">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/grasime_corporala.png" alt="Grăsime Corporală">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/apa_corporala.png" alt="Apa Corporala">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/masa_musculara.png" alt="Masa Culturala">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/rating_psihic.png" alt="Rating Psihic">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/masa_osoasa.png" alt="Masa Osoasa">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/BMR.png" alt="BMR">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/varsta_metabolica.png" alt="Vârstă Metabolică">
                                </th>
                                <th scope="col">
                                    <img src="assets/images/admin/grasime_viscerala.png" alt="Grăsime Viscerală">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of client.clients_profile">
                                <td class="align-middle text-center">{{ item.data }}</td>
                                <td class="align-middle text-center">{{ item.greutate }} kg</td>
                                <td class="align-middle text-center">{{ item.grasime_corporala }} %</td>
                                <td class="align-middle text-center">{{ item.apa_corporala }} %</td>
                                <td class="align-middle text-center">{{ item.masa_musculara }} </td>
                                <td class="align-middle text-center">{{ item.rating_psihic }}</td>
                                <td class="align-middle text-center">{{ item.masa_osoasa }}</td>
                                <td class="align-middle text-center">{{ item.BMR }}</td>
                                <td class="align-middle text-center">{{ item.varsta_metabolica }} ani</td>
                                <td class="align-middle text-center">{{ item.grasime_viscerala }}</td>
                                <td class="align-middle text-center">{{ item.observations }}</td>
                                <td class="align-middle text-center options" *ngIf="!isPrint">
                                    <i class="fa fa-edit" (click)="selectForEdit(item)" data-toggle="modal" data-target="#editModal"></i>
                                    <i class="fa fa-trash" (click)="deleteProfileEntry(item.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12">
                <h2>Măsurători</h2>
                <div class="table-responsive mt-5" *ngIf="client?.clients_profile">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">Data</th>
                                <th scope="col">Braț stâng</th>
                                <th scope="col">Braț drept</th>
                                <th scope="col">Piept</th>
                                <th scope="col">Talie</th>
                                <th scope="col">Șolduri</th>
                                <th scope="col">Fese</th>
                                <th scope="col">Picioare</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of client.clients_profile">
                                <td class="align-middle text-center">{{ item.data }}</td>
                                <td class="align-middle text-center">{{ item.brat_stanga }} cm</td>
                                <td class="align-middle text-center">{{ item.brat_dreapta }} cm</td>
                                <td class="align-middle text-center">{{ item.piept }} cm</td>
                                <td class="align-middle text-center">{{ item.talie }} cm</td>
                                <td class="align-middle text-center">{{ item.solduri }} cm</td>
                                <td class="align-middle text-center">{{ item.fese }} cm</td>
                                <td class="align-middle text-center">{{ item.picioare }} cm</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="chart">
                    <apx-chart [series]="chartMeasurements.series" [chart]="chartMeasurements.chart" [xaxis]="chartMeasurements.xaxis" [stroke]="chartMeasurements.stroke" [tooltip]="chartMeasurements.tooltip" [dataLabels]="chartMeasurements.dataLabels"></apx-chart>
                </div>
            </div>
            <div class="col-12 mt-5">
                <h2>Procentul de grăsime corporală pentru adulți</h2>
                <img class="d-block mx-auto" src="./assets/images/admin/grasime_corporala_grafic.png" alt="Grasime Corporala Grafic" width="850px">
                <img class="d-block mx-auto mt-5" src="./assets/images/admin/evaluare_fizica.png" alt="Evaluare fizică" width="500px">
                <img class="d-block mx-auto mt-5 mb-5" src="./assets/images/admin/hidratare.png" alt="Nivel optim de hidratare" width="500px">
                <h2>Evaluare stare fizică corp</h2>
                <img class="d-block mx-auto mb-5" src="./assets/images/admin/rating_psihic_grafic.jpg" alt="Rating Psihic Grafic" width="450px">
                <h2>Masă osoasă</h2>
                <img class="d-block mx-auto" src="./assets/images/admin/masa_osoasa_grafic.png" alt="Masă Osoasă Grafic" width="400px">
                <h2>Indice masă corporală</h2>
                <img class="d-block mx-auto mt-2" src="./assets/images/admin/indice_masa_corporala.png" alt="Indice masă corporală Grafic" width="850px">
                <img class="d-block mx-auto mt-4" src="./assets/images/admin/ultimul_tabel.png" alt="Indice masă corporală Grafic" width="850px">
            </div>
        </div>
    </div>
</div>


<!-- client to edit modal -->

<!-- Modal -->
<div id="editModal" #editModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifică client</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="editForm" (ngSubmit)="submitEdit()" class="login100-form validate-form">

                    <!-- input greutate client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="greutate" class="input100 form-control" type="text" placeholder="Greutate" [ngClass]="{ 'is-invalid': greutate.invalid && (greutate.dirty || greutate.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input grasime_corporala client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="grasime_corporala" class="input100 form-control" type="text" placeholder="Grasime Corporala" [ngClass]="{ 'is-invalid': grasime_corporala.invalid && (grasime_corporala.dirty || grasime_corporala.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input apa_corporala client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="apa_corporala" class="input100 form-control" type="text" placeholder="Apa Corporala" [ngClass]="{ 'is-invalid': apa_corporala.invalid && (apa_corporala.dirty || apa_corporala.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input masa_musculara client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="masa_musculara" class="input100 form-control" type="text" placeholder="Masa Musculara" [ngClass]="{ 'is-invalid': masa_musculara.invalid && (masa_musculara.dirty || masa_musculara.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input rating_psihic client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="rating_psihic" class="input100 form-control" type="text" placeholder="Rating Psihic" [ngClass]="{ 'is-invalid': rating_psihic.invalid && (rating_psihic.dirty || rating_psihic.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input masa_osoasa client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="masa_osoasa" class="input100 form-control" type="text" placeholder="Masa Osoasa" [ngClass]="{ 'is-invalid': masa_osoasa.invalid && (masa_osoasa.dirty || masa_osoasa.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input BMR client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="BMR" class="input100 form-control" type="text" placeholder="BMR" [ngClass]="{ 'is-invalid': BMR.invalid && (BMR.dirty || BMR.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input varsta_metabolica client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="varsta_metabolica" class="input100 form-control" type="text" placeholder="Varsta Metabolica" [ngClass]="{ 'is-invalid': varsta_metabolica.invalid && (varsta_metabolica.dirty || varsta_metabolica.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input grasime_viscerala client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="grasime_viscerala" class="input100 form-control" type="text" placeholder="Grasime Viscerala" [ngClass]="{ 'is-invalid': grasime_viscerala.invalid && (grasime_viscerala.dirty || grasime_viscerala.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input brat_stanga client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="brat_stanga" class="input100 form-control" type="text" placeholder="Brat Stanga" [ngClass]="{ 'is-invalid': brat_stanga.invalid && (brat_stanga.dirty || brat_stanga.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input brat_dreapta client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="brat_dreapta" class="input100 form-control" type="text" placeholder="Brat Dreapta" [ngClass]="{ 'is-invalid': brat_dreapta.invalid && (brat_dreapta.dirty || brat_dreapta.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input piept client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="piept" class="input100 form-control" type="text" placeholder="Piept" [ngClass]="{ 'is-invalid': piept.invalid && (piept.dirty || piept.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input talie client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="talie" class="input100 form-control" type="text" placeholder="Talie" [ngClass]="{ 'is-invalid': talie.invalid && (talie.dirty || talie.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input solduri client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="solduri" class="input100 form-control" type="text" placeholder="Solduri" [ngClass]="{ 'is-invalid': solduri.invalid && (solduri.dirty || solduri.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input fese client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="fese" class="input100 form-control" type="text" placeholder="Fese" [ngClass]="{ 'is-invalid': fese.invalid && (fese.dirty || fese.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input picioare client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="picioare" class="input100 form-control" type="text" placeholder="Picioare" [ngClass]="{ 'is-invalid': picioare.invalid && (picioare.dirty || picioare.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input observations client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="observations" class="input100 form-control" type="text" placeholder="Observații" [ngClass]="{ 'is-invalid': observations.invalid && (observations.dirty || observations.touched) }">
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <button [disabled]="editForm.invalid" type="submit" class="btn-default-dark d-block text-center m-auto">
            Trimite
          </button>

                </form>


            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>
</div>



<!-- client to add modal -->

<!-- Modal -->
<div id="addModal" #addModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Adaugă intrare client</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="addForm" (ngSubmit)="submitAdd()" class="login100-form validate-form">

                    <!-- input greutate client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="greutate" class="input100 form-control" type="text" placeholder="Greutate" [ngClass]="{ 'is-invalid': greutate.invalid && (greutate.dirty || greutate.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input grasime_corporala client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="grasime_corporala" class="input100 form-control" type="text" placeholder="Grasime Corporala" [ngClass]="{ 'is-invalid': grasime_corporala.invalid && (grasime_corporala.dirty || grasime_corporala.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input apa_corporala client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="apa_corporala" class="input100 form-control" type="text" placeholder="Apa Corporala" [ngClass]="{ 'is-invalid': apa_corporala.invalid && (apa_corporala.dirty || apa_corporala.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input masa_musculara client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="masa_musculara" class="input100 form-control" type="text" placeholder="Masa Musculara" [ngClass]="{ 'is-invalid': masa_musculara.invalid && (masa_musculara.dirty || masa_musculara.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input rating_psihic client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="rating_psihic" class="input100 form-control" type="text" placeholder="Rating Psihic" [ngClass]="{ 'is-invalid': rating_psihic.invalid && (rating_psihic.dirty || rating_psihic.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input masa_osoasa client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="masa_osoasa" class="input100 form-control" type="text" placeholder="Masa Osoasa" [ngClass]="{ 'is-invalid': masa_osoasa.invalid && (masa_osoasa.dirty || masa_osoasa.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input BMR client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="BMR" class="input100 form-control" type="text" placeholder="BMR" [ngClass]="{ 'is-invalid': BMR.invalid && (BMR.dirty || BMR.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input varsta_metabolica client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="varsta_metabolica" class="input100 form-control" type="text" placeholder="Varsta Metabolica" [ngClass]="{ 'is-invalid': varsta_metabolica.invalid && (varsta_metabolica.dirty || varsta_metabolica.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input grasime_viscerala client -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="grasime_viscerala" class="input100 form-control" type="text" placeholder="Grasime Viscerala" [ngClass]="{ 'is-invalid': grasime_viscerala.invalid && (grasime_viscerala.dirty || grasime_viscerala.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input brat_stanga client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="brat_stanga" class="input100 form-control" type="text" placeholder="Brat Stanga" [ngClass]="{ 'is-invalid': brat_stanga.invalid && (brat_stanga.dirty || brat_stanga.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input brat_dreapta client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="brat_dreapta" class="input100 form-control" type="text" placeholder="Brat Dreapta" [ngClass]="{ 'is-invalid': brat_dreapta.invalid && (brat_dreapta.dirty || brat_dreapta.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input piept client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="piept" class="input100 form-control" type="text" placeholder="Piept" [ngClass]="{ 'is-invalid': piept.invalid && (piept.dirty || piept.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input talie client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="talie" class="input100 form-control" type="text" placeholder="Talie" [ngClass]="{ 'is-invalid': talie.invalid && (talie.dirty || talie.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input solduri client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="solduri" class="input100 form-control" type="text" placeholder="Solduri" [ngClass]="{ 'is-invalid': solduri.invalid && (solduri.dirty || solduri.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input fese client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="fese" class="input100 form-control" type="text" placeholder="Fese" [ngClass]="{ 'is-invalid': fese.invalid && (fese.dirty || fese.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input picioare client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="picioare" class="input100 form-control" type="text" placeholder="Picioare" [ngClass]="{ 'is-invalid': picioare.invalid && (picioare.dirty || picioare.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <!-- input observations client -->
                    <div class="wrap-input100 validate-input mb-3">
                        <input formControlName="observations" class="input100 form-control" type="text" placeholder="Obs" [ngClass]="{ 'is-invalid': observations.invalid && (observations.dirty || observations.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </span>
                    </div>

                    <button [disabled]="addForm.invalid" type="submit" class="btn-default-dark d-block text-center m-auto mt-3">
            Trimite
          </button>

                </form>


            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>
</div>