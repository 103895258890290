import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { ClientsProfile } from '../_models/Client';

@Injectable({
  providedIn: 'root',
})
export class ClientProfileService {
  constructor(private http: HttpClient) {}

  deleteProfileEntry(profileEntryID: number) {
    return this.http.delete(environment.URL + 'clientProfile/' + profileEntryID);
  }

  createProfileEntry(profile: ClientsProfile) {
    return this.http.post(environment.URL + 'clientProfile', profile);
  }

  editProfileEntry(profile: ClientsProfile) {
    return this.http.put(environment.URL + 'clientProfile/' + profile.id, profile);
  }
}
