import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

import { ClientService } from 'src/app/_services/client.service';
import { ClientProfileService } from 'src/app/_services/clientProfile.service';
import {
  ClasificationResult,
  Client,
  ClientsProfile,
  diseasModel,
} from 'src/app/_models/Client';

import { ChartComponent } from 'ng-apexcharts';
import { ChartOptions } from 'src/app/_models/Chart';

declare var ml5: any;

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css'],
})
export class ClientProfileComponent implements OnInit {
  AppComponent = new AppComponent(this.router);

  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartPatientEvolution: Partial<ChartOptions>;
  public chartDiseases: Partial<ChartOptions>;
  public chartMeasurements: Partial<ChartOptions>;

  @Input() isPrint: boolean;

  clientID: number;
  client: Client;

  indiceMasaCorporala;
  RMB;
  greutateaIdeala;

  @ViewChild('editModal') private editModal: ElementRef;
  editForm: FormGroup;

  @ViewChild('addModal') private addModal: ElementRef;
  addForm: FormGroup;

  clasificationResult: ClasificationResult[];
  lastEntry: ClientsProfile;
  diseasPrediction: number;

  constructor(
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private clientProfileService: ClientProfileService
  ) {
    this.chartPatientEvolution = {
      series: [
        {
          name: '',
          data: [1],
        },
      ],
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: ['1'],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
    };

    this.chartDiseases = {
      series: [
        {
          name: '',
          data: [1],
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: ['1'],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
    };

    this.chartMeasurements = {
      series: [
        {
          name: '',
          data: [1],
        },
      ],
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: ['1'],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
    };
  }

  async ngOnInit() {
    this.AppComponent.checkAuth();
    this.formsInitializer();
    this.getClient();
    this.getClientEvolution();
    // this.getClientPredictions();
    this.getClientMeasurements();
  }

  // async getClientPredictions(): Promise<any> {
  //   // add neuraNetwork options
  //   const nn = ml5.neuralNetwork({
  //     task: 'classification',
  //     debug: false,
  //   });

  //   // Step 4: add data to the neural network
  //   diseasModel.forEach((item) => {
  //     const inputs = {
  //       greutate: item.greutate,
  //       varsta: item.varsta,
  //       genul: item.genul,
  //       grasime_corporala: item.grasime_corporala,
  //     };
  //     const output = {
  //       boala: item.boala,
  //     };
  //     nn.addData(inputs, output);
  //   });

  //   nn.normalizeData();

  //   nn.train(
  //     {
  //       epochs: 50,
  //     },
  //     () => {
  //       var person = {
  //         greutate: this.lastEntry.greutate,
  //         varsta: this.client.age,
  //         genul: this.client.gen,
  //         grasime_corporala: this.lastEntry.grasime_corporala,
  //       };

  //       nn.classify(person, (error, result) => {
  //         if (error) {
  //           console.error(error);
  //           return;
  //         }
  //         this.clasificationResult = result;
  //         var arrayDiseasePercentage = [];
  //         var arrayDiseaseLabel = [];

  //         this.clasificationResult.forEach((element) => {
  //           element.confidence = parseFloat(
  //             (element.confidence * 100).toFixed(2)
  //           );
  //           if (element.label != 'ok') {
  //             arrayDiseasePercentage.push(element.confidence);
  //             arrayDiseaseLabel.push(element.label);
  //           }
  //         });

  //         this.diseasPrediction = 100 - this.clasificationResult[0].confidence;

  //         // update disease prediction chart
  //         this.chartDiseases.series = [
  //           {
  //             name: 'Șanse',
  //             data: arrayDiseasePercentage,
  //           },
  //         ];

  //         this.chartDiseases.xaxis = {
  //           categories: arrayDiseaseLabel,
  //         };
  //       });
  //     }
  //   );
  // }

  formsInitializer() {
    this.editForm = this.formBuilder.group({
      id: [],
      data: [],
      created_at: [],
      updated_at: [],
      client_id: [],
      greutate: [],
      grasime_corporala: [],
      apa_corporala: [],
      masa_musculara: [],
      rating_psihic: [],
      masa_osoasa: [],
      BMR: [],
      varsta_metabolica: [],
      grasime_viscerala: [],
      brat_stanga: [],
      brat_dreapta: [],
      piept: [],
      talie: [],
      solduri: [],
      fese: [],
      picioare: [],
      observations: [' '],
    });

    this.addForm = this.formBuilder.group({
      client_id: [],
      greutate: [],
      grasime_corporala: [],
      apa_corporala: [],
      masa_musculara: [],
      rating_psihic: [],
      masa_osoasa: [],
      BMR: [],
      varsta_metabolica: [],
      grasime_viscerala: [],
      brat_stanga: [],
      brat_dreapta: [],
      piept: [],
      talie: [],
      solduri: [],
      fese: [],
      picioare: [],
      observations: [' '],
    });
  }

  getClient() {
    this.route.params.subscribe((params) => {
      this.clientID = params.id;
      this.clientService.getClient(this.clientID).subscribe(
        (data) => {
          this.client = data;
          this.client.height = parseFloat(this.client.height.toFixed(2));
          this.client.clients_profile.sort(
            (a, b) => new Date(b.data).getTime() - new Date(a.data).getTime()
          );
          this.getStatistics();
        },
        (error) => {
          this.toastr.errorToastr(
            error.message,
            'Clientul nu au putut fi preluat! '
          );
        }
      );
    });
  }

  getClientEvolution() {
    this.clientService.getClientEvolution(this.clientID).subscribe(
      (data) => {
        this.chartPatientEvolution.series = data[0][0];
        this.chartPatientEvolution.xaxis = {
          type: 'datetime',
          categories: data['xaxis'],
        };
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Diagrama profilului clientului nu au putut fi preluată! '
        );
      }
    );
  }

  getClientMeasurements() {
    this.clientService.getClientMeasurements(this.clientID).subscribe(
      (data) => {        
        this.chartMeasurements.series = data[0][0];
        this.chartMeasurements.xaxis = {
          type: 'datetime',
          categories: data['xaxis'],
        };
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Diagrama măsurătorilor clientului nu au putut fi preluată! '
        );
      }
    );
  }

  goToClient() {
    this.router.navigate(['admin/clients']);
  }

  deleteProfileEntry(profileEntryID) {
    this.clientProfileService.deleteProfileEntry(profileEntryID).subscribe(
      () => {
        this.getClient();
        this.getClientEvolution();
        // this.getClientPredictions();
        this.getClientMeasurements();
        this.toastr.successToastr('Intrarea a fost ștearsă din listă! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Intrarea NU a putut fi ștersă! '
        );
      }
    );
  }

  submitAdd() {
    this.addForm.controls['client_id'].setValue(this.client.id);
    this.addForm.addControl('data', new FormControl(new Date()));
    this.addForm.controls['greutate'].setValue(
      parseFloat(this.addForm.value.greutate)
    );
    this.addForm.controls['grasime_corporala'].setValue(
      parseFloat(this.addForm.value.grasime_corporala)
    );
    this.addForm.controls['apa_corporala'].setValue(
      parseFloat(this.addForm.value.apa_corporala)
    );
    this.addForm.controls['masa_musculara'].setValue(
      parseFloat(this.addForm.value.masa_musculara)
    );
    this.addForm.controls['rating_psihic'].setValue(
      parseFloat(this.addForm.value.rating_psihic)
    );
    this.addForm.controls['masa_osoasa'].setValue(
      parseFloat(this.addForm.value.masa_osoasa)
    );
    this.addForm.controls['BMR'].setValue(parseFloat(this.addForm.value.BMR));
    this.addForm.controls['varsta_metabolica'].setValue(
      parseFloat(this.addForm.value.varsta_metabolica)
    );
    this.addForm.controls['grasime_viscerala'].setValue(
      parseFloat(this.addForm.value.grasime_viscerala)
    );
    this.addForm.controls['brat_stanga'].setValue(
      parseFloat(this.addForm.value.brat_stanga)
    );
    this.addForm.controls['brat_dreapta'].setValue(
      parseFloat(this.addForm.value.brat_dreapta)
    );
    this.addForm.controls['piept'].setValue(
      parseFloat(this.addForm.value.piept)
    );
    this.addForm.controls['talie'].setValue(
      parseFloat(this.addForm.value.talie)
    );
    this.addForm.controls['solduri'].setValue(
      parseFloat(this.addForm.value.solduri)
    );
    this.addForm.controls['fese'].setValue(parseFloat(this.addForm.value.fese));
    this.addForm.controls['picioare'].setValue(
      parseFloat(this.addForm.value.picioare)
    );

    this.clientProfileService.createProfileEntry(this.addForm.value).subscribe(
      () => {
        this.getClient();
        this.getClientEvolution();
        // this.getClientPredictions();
        this.getClientMeasurements();
        this.toastr.successToastr('Intrarea a fost adăugată cu success! ');
        this.addForm.reset();
        this.addModal.nativeElement.click();
      },
      (error) => {
        this.toastr.errorToastr(error.message, 'Intrarea NU a fost adăugată! ');
      }
    );
  }

  selectForEdit(profileEntry: ClientsProfile) {
    this.editForm.setValue(profileEntry);
  }

  submitEdit() {
    this.clientProfileService.editProfileEntry(this.editForm.value).subscribe(
      () => {
        this.getClient();
        this.getClientEvolution();
        // this.getClientPredictions();
        this.getClientMeasurements();
        this.toastr.successToastr('Intrarea a fost modificata cu success! ');
        this.editForm.reset();
        this.editModal.nativeElement.click();
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Intrarea NU a fost modificată! '
        );
      }
    );
  }

  getStatistics() {
    this.lastEntry = this.client.clients_profile[0];
    if (this.lastEntry) {
      var inaltimeCM = this.client.height * 100;

      this.indiceMasaCorporala = (this.lastEntry.greutate / inaltimeCM).toFixed(
        2
      );
      if (this.client.gen == 'Feminin') {
        this.RMB = (
          655 +
          9.6 * this.lastEntry.greutate +
          1.8 * inaltimeCM -
          4.7 * this.client.age
        ).toFixed(0);
        this.greutateaIdeala =
          (50 + 0.75 * (inaltimeCM - 150) + (this.client.age - 20) / 4) * 0.9;
      } else {
        this.RMB = (
          66 +
          13.7 * this.lastEntry.greutate +
          5 * inaltimeCM -
          6.8 * this.client.age
        ).toFixed(0);
        this.greutateaIdeala =
          50 + 0.75 * (inaltimeCM - 150) + (this.client.age - 20) / 4;
      }
    }
  }

  printClient(id: number) {
    this.router.navigate(['/print-client', id]);
  }

  get client_id() {
    return this.addForm.get('client_id');
  }

  get greutate() {
    return this.addForm.get('greutate');
  }

  get grasime_corporala() {
    return this.addForm.get('grasime_corporala');
  }

  get apa_corporala() {
    return this.addForm.get('apa_corporala');
  }

  get masa_musculara() {
    return this.addForm.get('masa_musculara');
  }

  get rating_psihic() {
    return this.addForm.get('rating_psihic');
  }

  get masa_osoasa() {
    return this.addForm.get('masa_osoasa');
  }

  get BMR() {
    return this.addForm.get('BMR');
  }

  get varsta_metabolica() {
    return this.addForm.get('varsta_metabolica');
  }

  get grasime_viscerala() {
    return this.addForm.get('grasime_viscerala');
  }

  get brat_stanga() {
    return this.addForm.get('brat_stanga');
  }

  get brat_dreapta() {
    return this.addForm.get('brat_dreapta');
  }

  get piept() {
    return this.addForm.get('piept');
  }

  get talie() {
    return this.addForm.get('talie');
  }

  get solduri() {
    return this.addForm.get('solduri');
  }

  get fese() {
    return this.addForm.get('fese');
  }

  get picioare() {
    return this.addForm.get('picioare');
  }

  get observations() {
    return this.addForm.get('observations');
  }
}
