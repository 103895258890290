import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-print-client',
  templateUrl: './print-client.component.html',
  styleUrls: ['./print-client.component.css'],
})
export class PrintClientComponent implements OnInit {

  constructor(
    private router: Router,
    public toastr: ToastrManager,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.print();
  }

  print() {
    setTimeout(() => {
      window.print();
      setTimeout(() => {
        this.route.params.subscribe((params) => {
          this.router.navigate(['admin/client-profile/', params.id]);
        });
      }, 250);
    }, 5000);
  }
}
