import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Smart Life Style';

  constructor(private router: Router) {}

  public checkAuth() {
    var access_token = sessionStorage.getItem('access_token');
    if (!access_token) {
      this.router.navigate(['/login']);
    }
  }
}
