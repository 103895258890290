import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { TokenInterceptor } from './_services/token-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { LoginComponent } from './admin/login/login.component';
import { AdminComponent } from './admin/layout/admin-pannel/admin.component';
import { AdminHeaderComponent } from './admin/layout/admin-header/admin-header.component';
import { ScheduleComponent } from './admin/components/schedule/schedule.component';
import { FinanceComponent } from './admin/components/finance/finance/finance.component';
import { ClientsComponent } from './admin/components/clients/clients/clients.component';
import { Finance30Component } from './admin/components/finance/finance30/finance30.component';
import { FinanceAllComponent } from './admin/components/finance/finance-all/finance-all.component';
import { ClientProfileComponent } from './admin/components/clients/client-profile/client-profile.component';
import { PrintClientComponent } from './admin/print-client/print-client.component';


@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    LoginComponent,
    AdminComponent,
    AdminHeaderComponent,
    ScheduleComponent,
    FinanceComponent,
    ClientsComponent,
    Finance30Component,
    FinanceAllComponent,
    ClientProfileComponent,
    PrintClientComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgbModalModule,
    FlatpickrModule.forRoot(),
    NgApexchartsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
