import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin/layout/admin-pannel/admin.component';
import { LoginComponent } from './admin/login/login.component';
import { IndexComponent } from './index/index.component';

import { FinanceComponent } from './admin/components/finance/finance/finance.component';
import { ScheduleComponent } from './admin/components/schedule/schedule.component';
import { ClientsComponent } from './admin/components/clients/clients/clients.component';
import { ClientProfileComponent } from './admin/components/clients/client-profile/client-profile.component';
import { PrintClientComponent } from './admin/print-client/print-client.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/index',
    pathMatch: 'full',
  },
  {
    path: 'index',
    component: IndexComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'schedule', pathMatch: 'full' },
      { path: 'schedule', component: ScheduleComponent },
      { path: 'finance', component: FinanceComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'client-profile/:id', component: ClientProfileComponent },
    ],
  },
  { path: 'print-client/:id', component: PrintClientComponent },
  { path: '**', redirectTo: '/index' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
