import { Injectable } from '@angular/core';
import { Schedule } from '../_models/Schedule';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CalendarEvent } from 'angular-calendar';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(private http: HttpClient) {}

  getSchedules() {
    return this.http.get<CalendarEvent[]>(environment.URL + 'schedule');
  }

  getSchedulesAll() {
    return this.http.get<CalendarEvent[]>(environment.URL + 'scheduleAll');
  }

  createSchedule(schedule: Schedule) {
    return this.http.post(environment.URL + 'schedule', schedule);
  }

  deleteSchedule(scheduleID: number) {
    return this.http.delete(environment.URL + 'schedule/' + scheduleID);
  }

  editSchedule(schedule: Schedule) {
    return this.http.put(environment.URL + 'schedule/' + schedule.id, schedule);
  }

  // ----------------   Finance PART of the SCHEDULES   ----------------

  scheduleIncome30() {
    return this.http.get(environment.URL + 'scheduleIncome30');
  }

  scheduleIncomeAll() {
    return this.http.get(environment.URL + 'scheduleIncomeAll');
  }
}
