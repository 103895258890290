import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.preloader').delay(1000).fadeOut(500);
  }

}
