import { Component, OnInit, ViewChild } from '@angular/core';
import { ScheduleService } from 'src/app/_services/schedule.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ChartComponent} from 'ng-apexcharts';
import { ChartOptions } from 'src/app/_models/Chart';

@Component({
  selector: 'app-finance30',
  templateUrl: './finance30.component.html',
  styleUrls: ['./finance30.component.css'],
})
export class Finance30Component implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor(
    public toastr: ToastrManager,
    private scheduleService: ScheduleService
  ) {
    this.chartOptions = {
      series: [
        {
          name: '',
          data: [1],
        },
      ],
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: ['1'],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
    };
  }

  ngOnInit(): void {
    this.scheduleIncome30();
  }

  scheduleIncome30() {
    this.scheduleService.scheduleIncome30().subscribe(
      (data) => {
        this.chartOptions.series = [data[0][0]];
        this.chartOptions.xaxis = {
          type: 'datetime',
          categories: data[0]['xaxis'],
        };
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Datele statistice nu au putut fi preluate! '
        );
      }
    );
  }
}
