import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Client } from 'src/app/_models/Client';
import { ClientService } from 'src/app/_services/client.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
})
export class ClientsComponent implements OnInit {
  AppComponent = new AppComponent(this.router);

  clients: Client[];
  term: string;
  clientToEdit: Client;

  @ViewChild('editModal') private editModal: ElementRef;
  editForm: FormGroup;

  @ViewChild('addModal') private addModal: ElementRef;
  addForm: FormGroup;

  itemPerPage = 14;
  curentPage = 1;
  filterTerm = '';

  constructor(
    private clientService: ClientService,
    public toastr: ToastrManager,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.AppComponent.checkAuth();
    this.getClients();
    this.formsInitializer();
  }

  formsInitializer() {
    this.editForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      height: ['', Validators.required],
      age: ['', Validators.required],
      gen: ['', Validators.required],
    });

    this.addForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      height: ['', Validators.required],
      age: ['', Validators.required],
      gen: ['', Validators.required],
    });
  }

  getClients() {
    this.clientService.getClients().subscribe(
      (data) => {
        this.clients = data;
        this.clients.forEach((element) => {
          element.height =
            Math.round((element.height + Number.EPSILON) * 100) / 100;
          delete element['updated_at'];
          delete element['created_at'];
        });
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Clienții nu au putut fi preluați! '
        );
      }
    );
  }

  deleteClient(clientID) {
    this.clientService.deleteClient(clientID).subscribe(
      () => {
        this.getClients();
        this.toastr.successToastr('Clientul a fost șters din lista! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Clientul NU a putut fi șters! '
        );
      }
    );
  }

  selectForEdit(client: Client) {
    this.editForm.setValue(client);
  }

  submitEdit() {
    this.clientService.editClient(this.editForm.value).subscribe(
      () => {
        this.getClients();
        this.editModal.nativeElement.click();
        this.toastr.successToastr('Clientul a fost editat cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Clientul nu au putut fi modificat! '
        );
      }
    );
  }

  submitAdd() {
    this.addForm.value['height'] = Number.parseFloat(
      this.addForm.value['height']
    );
    this.addForm.value['age'] = Number.parseInt(this.addForm.value['age']);

    this.clientService.createClient(this.addForm.value).subscribe(
      () => {
        this.getClients();
        this.addForm.reset();
        this.addModal.nativeElement.click();
        this.toastr.successToastr('Clientul a fost adăugat cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Clientul nu au putut fi adăugat! '
        );
      }
    );
  }

  viewProfile(clientID) {
    this.router.navigate(['admin/client-profile', clientID]);
  }

  get id() {
    return this.editForm.get('id');
  }

  get name() {
    return this.editForm.get('name');
  }

  get phone() {
    return this.editForm.get('phone');
  }

  get height() {
    return this.editForm.get('height');
  }

  get age() {
    return this.editForm.get('age');
  }

  get gen() {
    return this.editForm.get('gen');
  }
}
