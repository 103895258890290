<div class="adminHeader">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-xs-4 col-md-4">
        <div class="logout left-align">
          <div class="btn btn-info" routerLink="schedule"> <i class="fa fa-calendar pr-2"></i> Program </div>
          <div class="btn btn-info" routerLink="finance"> <i class="fa fa-line-chart pr-2"></i> Finanțe </div>
          <div class="btn btn-info" routerLink="clients"> <i class="fa fa-line-chart pr-2"></i> Clienți </div>
        </div>
      </div>
      <div class="col-xs-4 col-md-4">
        <h3 class="text-center">Smart Life Style</h3>
      </div>
      <div class="col-xs-4 col-md-4">
        <div class="logout right-align">
          <div class="btn btn-info" (click)="logout()"> Delogare <i class="fa fa-sign-out pl-1"></i></div>
        </div>
      </div>
    </div>
  </div>
</div>
