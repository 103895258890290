<div class="template">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-11 col-sm-11 col-md-10 col-lg-10">
        <h2>Finanțe</h2>
      </div>
    </div>
    <hr>

    <div class="row text-center">
      <div class="col-12">
        <h2 class="mt-3"> Venituri ultimele 30 de zile</h2>
        <app-finance30></app-finance30>
      </div>
      <div class="col-12">
        <h2 class="mt-3"> Venituri totale</h2>
        <app-finance-all></app-finance-all>
      </div>
    </div>
  </div>
</div>
